import * as React from 'react';

import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-theme-material-ui';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import { Button } from '@mui/material/';

const NotFoundPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;

    return (
        <Layout location={location} title={siteTitle}>
            <StaticImage src={'../images/404.jpg'} alt="" layout="fullWidth" />

            <Button
                variant="contained"
                color="primary"
                component={Link}
                to={'/blog'}
                fullWidth
            >
                Back to our Blog
            </Button>
        </Layout>
    );
};

export default NotFoundPage;

export const Head = () => <Seo title="404: Not Found" />;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
